import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import SelectCards01 from 'components/Outdoor_Cameras/IN-9008_HD/Quick_Installation/Ethernet/SelectCards01';
import SelectCards02 from 'components/Outdoor_Cameras/IN-9008_HD/Quick_Installation/Ethernet/SelectCards02';
import NavButtons from 'components/Outdoor_Cameras/IN-9008_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9008 Full HD Quick Installation",
  "path": "/Outdoor_Cameras/IN-9008_HD/Quick_Installation/Ethernet/",
  "dateChanged": "2018-01-22",
  "author": "Mike Polinowski",
  "excerpt": "IN-9008 Full HD Quick Installation",
  "image": "../../P_SearchThumb_IN-9008HD_Quick_Installation.png",
  "social": "/images/Search/P_SearchThumb_IN-9008HD_Quick_Installation.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-9008HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='IN-9008 Full HD Quick Installation' dateChanged='2018-01-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='IN-9008 Full HD Quick Installation' image='/images/Search/P_SearchThumb_IN-9008HD_Quick_Installation.png' twitter='/images/Search/P_SearchThumb_IN-9008HD_Quick_Installation.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-9008_HD/Schnell_Installation/Ethernet/' locationFR='/fr/Outdoor_Cameras/IN-9008_HD/Quick_Installation/Ethernet/' crumbLabel="Installation" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <Link to="/Outdoor_Cameras/IN-9008_HD/Quick_Installation/" mdxType="Link"><EuiButton color="danger" fill mdxType="EuiButton">Return to Section I</EuiButton></Link>
    <h2 {...{
      "id": "iii-a-power--ethernet-cable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iii-a-power--ethernet-cable",
        "aria-label": "iii a power  ethernet cable permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`III-a Power & Ethernet Cable`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9a506cddc79df1dd3a79dd0ba3bb66ce/99072/IN-9008-Quick-Installation-III-A.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABWUlEQVQoz5WT23LkIAxE/f+/mKfsMl5fwOYuJIGcsplMUlOp1M55A9TVjQRDrdU5t21bzvl4kUFEmJmIaq0vi4/jqLUi4vE6pzMApJSY+X8EpRTnXGvt7kxEACAix3HIxS/iEMKyLN1puO+J1Fr5goh+1PfNnPMj41AvmkgGQEQiKqUQ0aP6SczMPfMpVrdb9IFyzt4n73POMUZrLRFprY0xMSWtNQDM8zxNUymlz6W1NlhrY4yYktfm3ziu62r3fTNmmqZ1XZVSo1J/3/+klJZ5UUohIjOLCCIOxpjTJ6VsnbN23/fifE4nAFC5SsGGJNdEQwillH3frbUppYGZzxgiWEqIEUsBs9VaiajnX8eRY+yXbK2FEIwxWuvT+XtLENE7l81GSEjU2/X+9qZvt+9ld7+vUX02M8Zo5oUQ+7GIhG3Lzv34foenNRG5i/5Pek7nvbUWAJ7EHyImM8PXiOWTAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9a506cddc79df1dd3a79dd0ba3bb66ce/e4706/IN-9008-Quick-Installation-III-A.avif 230w", "/en/static/9a506cddc79df1dd3a79dd0ba3bb66ce/d1af7/IN-9008-Quick-Installation-III-A.avif 460w", "/en/static/9a506cddc79df1dd3a79dd0ba3bb66ce/899b7/IN-9008-Quick-Installation-III-A.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9a506cddc79df1dd3a79dd0ba3bb66ce/a0b58/IN-9008-Quick-Installation-III-A.webp 230w", "/en/static/9a506cddc79df1dd3a79dd0ba3bb66ce/bc10c/IN-9008-Quick-Installation-III-A.webp 460w", "/en/static/9a506cddc79df1dd3a79dd0ba3bb66ce/6366e/IN-9008-Quick-Installation-III-A.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9a506cddc79df1dd3a79dd0ba3bb66ce/81c8e/IN-9008-Quick-Installation-III-A.png 230w", "/en/static/9a506cddc79df1dd3a79dd0ba3bb66ce/08a84/IN-9008-Quick-Installation-III-A.png 460w", "/en/static/9a506cddc79df1dd3a79dd0ba3bb66ce/99072/IN-9008-Quick-Installation-III-A.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9a506cddc79df1dd3a79dd0ba3bb66ce/99072/IN-9008-Quick-Installation-III-A.png",
              "alt": "IN-9008 Full HD Schnell Installation",
              "title": "IN-9008 Full HD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Thread the 3m power and 3m Ethernet cable through the wall mount, screw cap, 2-holed sealing ring and the back plate of your camera.`}</p>
    <h2 {...{
      "id": "iii-b-sealing-ring",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iii-b-sealing-ring",
        "aria-label": "iii b sealing ring permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`III-b Sealing Ring`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/60f38a99c05c85f93e57d0a43803c067/99072/IN-9008-Quick-Installation-III-B.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABd0lEQVQoz22TzbKCMAyFff83csvahTuXzAi2CCM0/U9K6x0JIqP327U5aZITOKSUxnEchgEAHo9H3/d8tNY+F0IIfMnRxxsiOpRSEDGEgIgxRu+9W0BETs45xxiJiAX4Jud84LeFENfrVQgRY+ScsvB8g4ibxjnHgrVy13XH41FKWUrJOT93OOcAgDVVVY3jOM8zh9ZkADidTkIIay0iEtH2BAAYY2KMSqnz+TwMwzRN3NSr7RijEMJ7r5QCgN/i3LaU0hgzTRMArG1v4f2E//LlwqtySsla670PIRhjnHPe+70opaS1Zg2zmXogIq01AFhrlVKs2z9PROM4TtNkjIEFdnudmZnnmRY2M3/bzguftvkqpdT3fb0wDMNvjnOuaZq6rtu29d6vhsUYu64LIaSUqqq6XC45531xpZQxBhHv93vTNMaYz56JyHvPX2hd103TtG2bUtqSASAs8MBSyu9VlVL4f5jnefPja8+3201r7ZwLIXDlP/yiKSyq5xksAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/60f38a99c05c85f93e57d0a43803c067/e4706/IN-9008-Quick-Installation-III-B.avif 230w", "/en/static/60f38a99c05c85f93e57d0a43803c067/d1af7/IN-9008-Quick-Installation-III-B.avif 460w", "/en/static/60f38a99c05c85f93e57d0a43803c067/899b7/IN-9008-Quick-Installation-III-B.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/60f38a99c05c85f93e57d0a43803c067/a0b58/IN-9008-Quick-Installation-III-B.webp 230w", "/en/static/60f38a99c05c85f93e57d0a43803c067/bc10c/IN-9008-Quick-Installation-III-B.webp 460w", "/en/static/60f38a99c05c85f93e57d0a43803c067/6366e/IN-9008-Quick-Installation-III-B.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/60f38a99c05c85f93e57d0a43803c067/81c8e/IN-9008-Quick-Installation-III-B.png 230w", "/en/static/60f38a99c05c85f93e57d0a43803c067/08a84/IN-9008-Quick-Installation-III-B.png 460w", "/en/static/60f38a99c05c85f93e57d0a43803c067/99072/IN-9008-Quick-Installation-III-B.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/60f38a99c05c85f93e57d0a43803c067/99072/IN-9008-Quick-Installation-III-B.png",
              "alt": "IN-9008 Full HD Schnell Installation",
              "title": "IN-9008 Full HD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Press in the Sealing Ring.`}</p>
    <h2 {...{
      "id": "iii-c-power-ethernet--antenna-cable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iii-c-power-ethernet--antenna-cable",
        "aria-label": "iii c power ethernet  antenna cable permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`III-c Power, Ethernet & Antenna Cable`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6505ffe71434c83bba3e89bf8ffa790d/99072/IN-9008-Quick-Installation-III-C.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABTUlEQVQoz6WSy47bMAxF/f9/GARIkEWQyI9gbEl8itQg5jTNYqYo2rOzySteXmkwM1U1s7ajqsxsZr13MxOR+M/MIsI7suPuAyIej8fz+Xzaud1u9/sdEXvviHg4HE6n0+VyGcdxWZaU0jRN8zxPadSmQ3dHRKgVay2lCFJ/g5kBIM76jXtX7b0PX5/NnNndncW79x9wf5bczJifYjMjIiEWACZSgPfWWN7fMPdu1mWfzMyIqCINkYlw215iVa21bttWa8VfAACUUj8+LAIj5lJK3TZmXqaptRYDI/laKxHx7jNclJzHlJj5Kc45X6/Xx+ORc57nuZQSrXE3cUrO+d1RzvlLTETLsqzrGisAQAQjIoi4risAvC487KSUEHFQVQAIbzHT3UUkng0ixsJE9Nq5tfbMWGSINaIWrycKMfzPDK8Y/qb7e/G/8V/iTzIQNFShTDXVAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6505ffe71434c83bba3e89bf8ffa790d/e4706/IN-9008-Quick-Installation-III-C.avif 230w", "/en/static/6505ffe71434c83bba3e89bf8ffa790d/d1af7/IN-9008-Quick-Installation-III-C.avif 460w", "/en/static/6505ffe71434c83bba3e89bf8ffa790d/899b7/IN-9008-Quick-Installation-III-C.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6505ffe71434c83bba3e89bf8ffa790d/a0b58/IN-9008-Quick-Installation-III-C.webp 230w", "/en/static/6505ffe71434c83bba3e89bf8ffa790d/bc10c/IN-9008-Quick-Installation-III-C.webp 460w", "/en/static/6505ffe71434c83bba3e89bf8ffa790d/6366e/IN-9008-Quick-Installation-III-C.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6505ffe71434c83bba3e89bf8ffa790d/81c8e/IN-9008-Quick-Installation-III-C.png 230w", "/en/static/6505ffe71434c83bba3e89bf8ffa790d/08a84/IN-9008-Quick-Installation-III-C.png 460w", "/en/static/6505ffe71434c83bba3e89bf8ffa790d/99072/IN-9008-Quick-Installation-III-C.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6505ffe71434c83bba3e89bf8ffa790d/99072/IN-9008-Quick-Installation-III-C.png",
              "alt": "IN-9008 Full HD Schnell Installation",
              "title": "IN-9008 Full HD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Plug in the 3m Power Supply and 3m Ethernet Cable. Fasten the Antenna Cable - if previously removed.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <SelectCards01 mdxType="SelectCards01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "iii-h-reassemble",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iii-h-reassemble",
        "aria-label": "iii h reassemble permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`III-h Reassemble`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/263d888cc90ee26a8e1c55c0de67e145/99072/IN-9008-Quick-Installation-III-H.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABMElEQVQoz6VS2W4DIQzc///CvCXSVonCchqwuakWolXUNlKjzBsw47EHL0QkpVRKbduGiP0dLDlnRPTeA0AI4T1x/wCfictArRURj87rQCnlBzuEgIjH/T7zZHPO13W9XC7Ouev1ejqdGGOT5JxjjFlrnXMzoKnfxdNHCHG73davL2OMEOJ8PgshvPez1iE2xsQYZ4NLrVUpJaUEACQyxqD3jDF2v3PO51AAIJVijAGAEMJaG2N8BFZr9d6nlPYWW+u1xhjLcew958y3TQrhETnnAPAi7SHuo+QfT70jotY6hEBEu7iUYozRWgOA1lpJSUSTOpFzTjGmENIIKOdMRCmlZVRs+QmllFbrszMAGG2cddPz5ZK01igEQiTc8QjinxvWWps/iYjW2unTBn6LvwFP/DRglBSkZQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/263d888cc90ee26a8e1c55c0de67e145/e4706/IN-9008-Quick-Installation-III-H.avif 230w", "/en/static/263d888cc90ee26a8e1c55c0de67e145/d1af7/IN-9008-Quick-Installation-III-H.avif 460w", "/en/static/263d888cc90ee26a8e1c55c0de67e145/899b7/IN-9008-Quick-Installation-III-H.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/263d888cc90ee26a8e1c55c0de67e145/a0b58/IN-9008-Quick-Installation-III-H.webp 230w", "/en/static/263d888cc90ee26a8e1c55c0de67e145/bc10c/IN-9008-Quick-Installation-III-H.webp 460w", "/en/static/263d888cc90ee26a8e1c55c0de67e145/6366e/IN-9008-Quick-Installation-III-H.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/263d888cc90ee26a8e1c55c0de67e145/81c8e/IN-9008-Quick-Installation-III-H.png 230w", "/en/static/263d888cc90ee26a8e1c55c0de67e145/08a84/IN-9008-Quick-Installation-III-H.png 460w", "/en/static/263d888cc90ee26a8e1c55c0de67e145/99072/IN-9008-Quick-Installation-III-H.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/263d888cc90ee26a8e1c55c0de67e145/99072/IN-9008-Quick-Installation-III-H.png",
              "alt": "IN-9008 Full HD Schnell Installation",
              "title": "IN-9008 Full HD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Fasten the mainboard slide and use the 4 screws to mount the back plate back onto your camera casing. Screw the wall mount to the back of the camera.`}</p>
    <h2 {...{
      "id": "iii-i-internet-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iii-i-internet-access",
        "aria-label": "iii i internet access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`III-i Internet Access`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/090cabc1d5bce21fae4753680e67f574/99072/IN-9008-Quick-Installation-1-16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABgklEQVQoz5WRMUsDMRiG465ODk4OjlIUXEUQcRD8A+JPEEdB0MEOCqJgf4EKjvobFMRBFIoiKii9WrXXS3I52ssll0u8JNIWsUpr9eVZ8pE33/e9AQMr5xO5/OjWVWbzcjh7MZnL9y+fgcWTnqVT0JXB7M147mFs92Fk+25o4zazcz+wftO7et231h0wd1iZP8ILx/7sIZw+8Kb2K9N77sz+nwAwqD1XcLGMSi58g/4bJCK1SQux0p0A70oySlkU0TDkjLEokolokohYykRrbTsIaK1jzkkQYJ9UqzUhBEKo9PLqebBcdiFCQsRKqbZPAGOM1hph7PskSaS1lhC/UHCkUsaYNE1VQ7+ZIUTVWq1ZklIKIUznab/M1lglpVMshmEYUoowdl23bjam9d6P47fOPiEehDRi1togCBzHQQjzeoCsEWLEOW9n1toaE4aUECKSxFobc+55HsaYUso+1X7n+thKPj49McbsP1X/qjRNKaVCCK11c4umTDeBv6TaqfMHPlPbzBGNPJIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/090cabc1d5bce21fae4753680e67f574/e4706/IN-9008-Quick-Installation-1-16.avif 230w", "/en/static/090cabc1d5bce21fae4753680e67f574/d1af7/IN-9008-Quick-Installation-1-16.avif 460w", "/en/static/090cabc1d5bce21fae4753680e67f574/899b7/IN-9008-Quick-Installation-1-16.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/090cabc1d5bce21fae4753680e67f574/a0b58/IN-9008-Quick-Installation-1-16.webp 230w", "/en/static/090cabc1d5bce21fae4753680e67f574/bc10c/IN-9008-Quick-Installation-1-16.webp 460w", "/en/static/090cabc1d5bce21fae4753680e67f574/6366e/IN-9008-Quick-Installation-1-16.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/090cabc1d5bce21fae4753680e67f574/81c8e/IN-9008-Quick-Installation-1-16.png 230w", "/en/static/090cabc1d5bce21fae4753680e67f574/08a84/IN-9008-Quick-Installation-1-16.png 460w", "/en/static/090cabc1d5bce21fae4753680e67f574/99072/IN-9008-Quick-Installation-1-16.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/090cabc1d5bce21fae4753680e67f574/99072/IN-9008-Quick-Installation-1-16.png",
              "alt": "IN-9008 Full HD Schnell Installation",
              "title": "IN-9008 Full HD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`You need to `}<Link to="/Internet_Access/Port_Forwarding/" mdxType="Link">{`Forward a Port`}</Link>{` inside your router, if you want to access your camera over its `}<Link to="/Internet_Access/The_DDNS_Service/" mdxType="Link">{`DDNS Address`}</Link>{` from the internet. Alternatively, you can use the `}<Link to="/Web_User_Interface/1080p_Series/Network/Internet_Access/" mdxType="Link">{`P2P User ID`}</Link>{` to access your camera with our smartphone app (see Step 12 below).`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <SelectCards02 mdxType="SelectCards02" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "iii-l-final-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iii-l-final-installation",
        "aria-label": "iii l final installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`III-l Final Installation`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3ef84e5a50eb6c144e14c2ff42599721/99072/IN-9008-Quick-Installation-III-L.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABM0lEQVQoz42S7W7DIAxF8/5v2UpRI5rwacDYOEwLCuumdOr5SThwfcmUUtq2TWu9rqs6MMa0z5hEJOdcDhAx54yIn8qXq/u+v1t//TRd7kDESx8RvfdjrmuZiC5vRsQQwn9ya+2dnA9qrT+F1RNmTikBQIyx1poORGTIItLr7MNPzDxqKKVored5vt1uxph0MuYXkRhja61f9i2Pg5lZa71tm3Muxqi1Tikh4piCiHoWImLmqZ/RsxGRcy6EYK313ltrtdYAMGRENMYsy6KUijFO3fHexwPvvVLq+Xw653LOxhgA6GattZQiItZaRPyOPfKUUohof6EQGWMCADMTM5ZSRcoR+O9TjfC/XlwkhgAhJIAEkGPkUtrZ3/T2x933htiI1sdDzfNyvz/u9+RcY25nBV9N3DXx4deblgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ef84e5a50eb6c144e14c2ff42599721/e4706/IN-9008-Quick-Installation-III-L.avif 230w", "/en/static/3ef84e5a50eb6c144e14c2ff42599721/d1af7/IN-9008-Quick-Installation-III-L.avif 460w", "/en/static/3ef84e5a50eb6c144e14c2ff42599721/899b7/IN-9008-Quick-Installation-III-L.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3ef84e5a50eb6c144e14c2ff42599721/a0b58/IN-9008-Quick-Installation-III-L.webp 230w", "/en/static/3ef84e5a50eb6c144e14c2ff42599721/bc10c/IN-9008-Quick-Installation-III-L.webp 460w", "/en/static/3ef84e5a50eb6c144e14c2ff42599721/6366e/IN-9008-Quick-Installation-III-L.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ef84e5a50eb6c144e14c2ff42599721/81c8e/IN-9008-Quick-Installation-III-L.png 230w", "/en/static/3ef84e5a50eb6c144e14c2ff42599721/08a84/IN-9008-Quick-Installation-III-L.png 460w", "/en/static/3ef84e5a50eb6c144e14c2ff42599721/99072/IN-9008-Quick-Installation-III-L.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3ef84e5a50eb6c144e14c2ff42599721/99072/IN-9008-Quick-Installation-III-L.png",
              "alt": "IN-9008 Full HD Schnell Installation",
              "title": "IN-9008 Full HD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Install your camera and plug in the power supply. The camera should automatically reconnect itself to your WiFi network.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      